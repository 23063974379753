import React from 'react';
import ProjectCard from '../components/ProjectCard';
import { projects } from '../data/projects';
// import Wave from '../components/Wave';
import Hero from '../components/Hero';
import ContactForm from '../components/ContactForm';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';

const Home = () => {
  return (
    <div className='w-screen'>
      <section className='w-full bg-heroBackground'>
        <Hero />
      </section>
      <section className="max-w-screen-lg mx-auto p-4 rounded-lg m-4 my-32">
        {/* <Wave height="800px" viewBox="0 0 1440 500" /> */}
        
        {/* Contenido de la página */}
        <h1 className="text-3xl font-bold mb-6">Nuestros Proyectos</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>

        
      </section>
      <section id='testimonios' className="bg-sectionBackground w-full mx-auto p-4  py-32">
        <Testimonials />
      </section>
      <section id='servicios' className="w-full mx-auto  m-4 my-32">
        <Services />
      </section>
      <section id='contactanos' className="bg-sectionBackground w-full mx-auto p-4 rounded-lg py-32">
        <ContactForm />
      </section>
    </div>
  );
};

export default Home;
