export const projects = [
    {
        id: "saludando",
        type: "Aplicación Web",
        title: 'Saludando Benefisio',
        detailTitle: 'Saludando - Software personalizado para centro de pilates: Gestión de clientes, Calendario de Clases y automatización de Pagos ',
        description: 'Aplicación web para centro de pilates que permite gestionar clientes, clases y pagos.',
        image: '/assets/images/saludando.png', // Aquí pondrás la imagen del mockup
        link: 'https://benefisio-app.com/',
        category: 'React',
        technologies: ['React', 'TailwindCSS', "TypeScript", "Node.js", "MongoDB", "Clerk"],
        content: [
            { 
                type: "text", 
                text: [
                    "<em>Saludando</em> es una clínica de fisioterapia y clases de pilates.",
                    "Guiados por los requerimientos del equipo de Saludando desarrollamos un software personalizado, flexible y adaptado a las particulares de su negocio.",
                    "El software <u>redujo la carga de trabajo del personal</u>, ya que permite la gestión ágil de clientes para las clases de pilates y automatizó la gestión de los pagos.",
                ]
            },
            {
                type: "image",
                src: "/assets/images/saludando_detail_1.png",
            },
            { 
                type: "text", 
                text: [
                    "El <strong>mayor desafío</strong> fue desarrollar un programa flexible y versátil tomando en cuenta las múltiples excepciones y particularidades del negocio.",
                    "Así, el programa toma en cuenta las particularidades y excepciones de los alumnos, periodos de baja temporal, pago de otros beneficiarios, etc. que <u>no conseguían cubrir los software masivos e implicaba una sobrecarga de trabajo administrativo.</u>",
                ]
            },
            {
                type: "image",
                src: "/assets/images/saludando_detail_2.png",
            },
            {
                type: "text",
                text: [
                    "Con la finalidad de hacerlo fácilmente accesible desarrollamos el software como aplicación web utilizando las siguientes herramientas en programación:",
                ],
            },
            {
                type: "bullets",
                points: [
                    "<strong>Para el front:</strong> Framework VITE con React, TypeScript y TailWind para el CSS y Clerk como librería de autenticación de usuario.",
                    "<strong>Para el Back:</strong> Node.js y MongoDB para la base de datos.",
                ],
            },
            {
                type: "image",
                src: "/assets/images/saludando_detail_3.png",
            },
            {
                type: "bullets",
                points: [
                    "<u>Módulo de Gestión de Clientes:</u> que permite crear, editar, modificar y dar de baja a los clientes. Gestionar la características de la suscripción a las clases de pilates y los métodos de pago de cada cliente. Contempla casos extraordinarios como descuentos personalizados, bajas temporales de la membresía, pago de suscripciones por parte de beneficiarios.",
                    "<u>Módulo de Pagos:</u> permite la automatización de los pagos SEPA de las clases de pilates, según las particularidad de cada cliente. Gestiona con un solo click los pagos en efectivo y por tarjeta de clientes que pagan de forma anticipa",
                    "<u>Módulo de Calendario de clases:</u> Permite la creación masiva de las clases mensuales de pilates y configurar eventos en las distintas salas del centro, garantizando la plaza reservada de cada cliente.",
                    "<u>Módulo de reportes:</u> Permite visualizar de forma detallada los ingresos mensuales según el método de pago de las clases de pilates."
                ],
            },


        ],
    },
    {
        id: "de-alma-a-alma",
        type: "Pagina Web",
        title: 'De Alma a Alma',
        detailTitle: 'De Alma a Alma - Sitio Web Profesional',
        description: 'Sitio web de Carmen Cecilia Rodriguez, brillante psicóloga que ofrece múltiples servicios.',
        image: '/assets/images/carmen.png', // Aquí pondrás la imagen del mockup
        link: 'https://dealmaalma.com/',
        category: 'WordPress',
        technologies: ['Wordpress', 'HTML', 'CSS'],
        content: [
            { 
                type: "text-image", 
                src: "/assets/images/carmen_detail_1.png", 
                title: "Psicológa Sanitaria",  
                text: [
                    "Carmen es psicóloga y nos eligió para crear su Sitio Web personalizado.",
                    "Guiados por los requerimientos de Carmen desarrollamos su página web con un enfoque artesanal y acogedor, que permitiera mostrar sus servicios y su enfoque terapéutico.",
                    "A petición del cliente utilizamos WordPress con CCS Personalizado.",
                    "Adicionalmente gestionamos para ella el Hosting y dominio web.",
                    "También implementamos en la web el Banner de Cookies y Textos legales básicos de la AEPD.",
                ]
            },
            { 
                type: "image-text", 
                src: "/assets/images/carmen_detail_2.png", 
                text: [
                    "Carmen es psicóloga y terapeuta integradora, especializada en trauma, duelo y migración. Ella también es doula de muerte y acompaña a las personas para llevar en calma la etapa final de su vida.",
                    "Ayuda a las personas a conseguir la armonía entre tus pensamientos, emociones y acciones.",
                    "Esto debía verse reflejado en una web con un enfoque artesanal, acogedor y natural que transmitiera paz y tranquilidad. ",
                ]
            }
        ],
    },
    {
        id: "lunitas-y-duendes",
        title: 'Lunitas y Duendes',
        detailTitle: 'Tienda Online',
        type: "Pagina Web",
        description: 'Tienda online de productos para bebés y niños, desarrollada en Shopify.',
        image: '/assets/images/lunitas.png', // Aquí pondrás la imagen del mockup
        link: 'https://www.lunitasyduendes.com',
        category: 'Shopify',
        technologies: ['Shopify', 'HTML', 'CSS'],
        content: [
            {
                type: "text-imageDown-imageRight",
                title: "Lunitas y Duendes: Moda Infantil", 
                src: "/assets/images/lunitas_detail_1.png", 
                src2: "/assets/images/lunitas_detail_2.png", 
                text:  [
                    "Una tienda online para vender ropa para bebés.",
                    "Creada bajo la plataforma de Shopify, configuramos la plantilla y la personalizamos por medio de programación CSS.",
                    "Guiados por los requerimientos de la imagen de marca desarrollamos su página web con un enfoque tierno y minimalista, que permitiera resaltar sus productos.",
                    "También implementamos en la web el Banner de Cookies y Textos legales básicos de la AEPD.",
                    "Gestionamos el mantenimiento mensual que incluye gestión de herramienta email marketing, creación de nuevos productos, actualización de blog, FB pixel, carga de imágenes, optimización de velocidad, mejoras SEO, análisis de métricas mensuales: GA4 y mejoramiento CRO (Conversion Rate Optimización)"
                ]
            },
        ],
    },
    {
        id: "neerutech",
        type: "Pagina Web",
        title: 'NeeruTech',
        description: 'Optimiza tu éxito en línea: creación de sitios web, análisis basado en datos y mucha más.',
        image: '/assets/images/neerutech_new.png',
        link: 'https://www.neerutech.com',
        category: 'WordPress',
        technologies: ['Wix', 'HTML', 'CSS'],
        content: [
            
        ],
    },
    {
        id: "personal-brand",
        type: "Pagina Web",
        title: 'Personal Brand Mockup',
        description: 'Sitio Web para mostrar tu marca personal, skills y proyectos. Se puede usar para promocionarse y para que contacten contigo.',
        image: '/assets/images/personal_brand_new.png',
        link: 'https://gfranzella.github.io/personal_brand/index.html',
        category: 'HTML',
        technologies: ['HTML', 'CSS', 'JavaScript', 'Bootstrap'],
        content: [
            
        ],
    },
    {
        id: "nutricion",
        type: "Pagina Web",
        title: 'Plantilla de Web de Nutrición',
        description: 'Plantilla de sitio web one-pager para mostrar una marca personal para nutricionista.',
        image: '/assets/images/nutricion.png',
        link: 'https://gfranzella.github.io/nutrition-template-page/',
        category: 'HTML',
        technologies: ['HTML', 'CSS', 'JavaScript', 'Bootstrap'],
        content: [
            
        ],
    },
    {
        id: "lobstar",
        type: "Pagina Web",
        title: 'Restarante The Lobstar',
        description: 'Página para el restaurante The Lobstar, para permitir a los clientes visitar, mirar la carta, contactar y reservar.',
        image: '/assets/images/lobstar_new.png',
        link: 'https://thelobstar-1d345.web.app/',
        category: 'HTML',
        technologies: ['React', 'Gatsby', 'Bootstrap', 'CSS'],
        content: [
            
        ],
    },
    // Más proyectos
];