// import React from 'react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

//   Función para alternar el estado del menú
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleNavigation = (path, id) => {
        navigate(path);
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100); // Ajusta el tiempo de espera según sea necesario
    };

    return (
        <nav className="bg-neerutechBlue text-white p-4">
            <div className="max-w-screen-lg mx-auto flex justify-evenly items-center gap-4">
                {/* Logo */}
                <Link to="/" className="text-lg font-bold">
                <img 
                    src="/assets/images/logo_neerutech.png" 
                    alt="logo de Neerutech" 
                    className="h-14 w-auto" // Añade Tailwind para controlar el tamaño
                /> 
                </Link>

                {/* Icono de menú para móviles */}
                <div className="lg:hidden">
                    <button onClick={toggleMenu} className="focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
                        </svg>
                    </button>
                </div>

                {/* Links del menú, ocultos en móvil */}
            <div className='hidden lg:flex space-x-4'>
                    <Link to="/" className="hover:text-gray-400 block lg:inline-block">Inicio</Link>
                    <button onClick={() => handleNavigation('/', 'servicios')} className="hover:text-gray-400 block lg:inline-block">Servicios</button>
                    <button onClick={() => handleNavigation('/', 'contactanos')} className="hover:text-gray-400 block lg:inline-block">Contacto</button>
                </div>
            </div> 

            {/* Menú desplegable para móviles, visible sólo cuando el menú está abierto */}
            <div className={`lg:hidden ${isOpen ? 'block' : 'hidden'}`}>
                <Link to="/" className="block px-4 py-2 hover:bg-gray-700">Inicio</Link>
                <button onClick={() => handleNavigation('/', 'servicios')} className="block px-4 py-2 hover:bg-gray-700">Servicios</button>
                <button onClick={() => handleNavigation('/', 'contactanos')} className="block px-4 py-2 hover:bg-gray-700">Contacto</button>
            </div>
        </nav>
    );
};

export default Navbar;
